export enum PERMISSIONS {
  CREATE_USER = 'user:create',
  EDIT_USER = 'user:edit',
  VIEW_USER = 'user:view',
  DELETE_USER = 'user:delete',

  CREATE_TREATMENT = 'treatment:create',
  EDIT_TREATMENT = 'treatment:edit',
  VIEW_TREATMENT = 'treatment:view',

  CREATE_TP = 'treatmentplan:create',
  EDIT_TP = 'treatmentplan:edit',
  VIEW_TP = 'treatmentplan:view',
  DELETE_TP = 'treatmentplan:delete',

  CREATE_NOTE = 'note:create',
  EDIT_NOTE = 'note:edit',
  VIEW_NOTE = 'note:view',
  DELETE_NOTE = 'note:delete',

  CREATE_DOC = 'document:create',
  EDIT_DOC = 'document:edit',
  VIEW_DOC = 'document:view',
  DELETE_DOC = 'document:delete',

  CREATE_GROUP = 'group:create',
  EDIT_GROUP = 'group:edit',
  VIEW_GROUPS = 'group:view',
  DELETE_GROUPS = 'group:delete',

  CREATE_APPOINTMENT = 'appointment:create',
  EDIT_APPOINTMENT = 'appointment:edit',
  VIEW_APPOINTMENT = 'appointment:view',
  DELETE_APPOINTMENT = 'appointment:delete',
  ENABLE_OUTCOME_APPOINTMENT = 'appointment:outcome:submit:enable',

  CREATE_PERMISSION = 'permission:create',
  EDIT_PERMISSION = 'permission:edit',
  VIEW_PERMISSION = 'permission:view',
  DELETE_PERMISSION = 'permission:delete',

  CREATE_REFERRAL = 'referral:create',
  EDIT_REFERRAL = 'referral:edit',
  VIEW_REFERRAL = 'referral:view',
  DELETE_REFERRAL = 'referral:delete',

  CREATE_TPV = 'treatmentplanversion:create',
  EDIT_TPV = 'treatmentplanversion:edit',
  VIEW_TPV = 'treatmentplanversion:view',
  REOPEN_TPV = 'treatmentplanversion:edit:reopen',
  CREATE_REFINEMENT_TPV = 'treatmentplanversion:refinement:create',

  VIEW_CLINIC = 'clinic:view',
  CREATE_CLINIC = 'clinic:create',
  EDIT_CLINIC = 'clinic:edit',

  CREATE_TICKET = 'ticket:create',
  VIEW_TICKET = 'ticket:view',
  UPDATE_TICKET_STATUS = 'ticket:update-status',
  UPDATE_TICKET_URGENCY = 'ticket:update-urgency',
  ADD_TICKET_USER = 'ticket:add-user',
  REMOVE_TICKET_USER = 'ticket:remove-user',
  SEND_MESSAGE = 'ticket:send-message',
  BULK_ASSIGN = 'ticket:bulkReassignOwner',

  STAGE_PRE_ASSESSMENT_VIEW = 'stage:preassessment:view',
  STAGE_PRE_ASSESSMENT_EDIT = 'stage:preassessment:edit',
  STAGE_PRESCRIPTION_VIEW = 'stage:prescription:view',
  STAGE_PRESCRIPTION_EDIT = 'stage:prescription:edit',
  STAGE_TP_REVIEW_VIEW = 'stage:treatmentplanningreview:view',
  STAGE_TP_REVIEW_EDIT = 'stage:treatmentplanningreview:edit',
  STAGE_APPROVAL_POPUP_VIEW = 'button:tpvapprovalpopup:view',
  STAGE_IN_TREATMENT_VIEW = 'stage:intreatment:view',
  STAGE_IN_TREATMENT_EDIT = 'stage:intreatment:edit',
  STAGE_END_OF_TREATMENT_VIEW = 'stage:endoftreatment:view',
  STAGE_END_OF_TREATMENT_EDIT = 'stage:endoftreatment:edit',

  PATIENT_LIST_URGENCY_VIEW = 'urgency:view',
  PATIENT_LIST_APPOINTMENT_VIEW = 'tab:patientlist:appointment:view',

  DASHBOARD_PAGE = 'tab:dashboard:view',
  PATIENT_LIST = 'tab:patientlist:view',
  CALENDAR = 'tab:calendar:view',
  INBOX = 'tab:inbox:view',
  REFERRAL = 'tab:referral:view',
  CASE_LIBRARY = 'tab:caselibrary:view',
  SETTINGS = 'tab:setting:view',
  APPOINTMENTS = 'tab:appointment:view',
  APPOINTMENTS_PATIENT_CARD = 'tab:patientCard:appointment:view',
  APPOINTMENTS_PATIENT_CARD_EDIT = 'aftersales:appointment:edit',

  GENERAL_SETTINGS = 'card:generalsetting:view',
  BRANCH_SETTINGS = 'card:branchsetting:view',
  USER_SETTINGS = 'card:usersetting:view',
  LOGIN_SECURITY = 'card:loginsecurity:view',
  APPOINTMENT = 'card:appointment:view',
  APPOINTMENT_PREFERENCE = 'card:appointmentpreferences:view',
  TREATMENT_PLANNING_PREFERENCE = 'card:treatmentplanningpreferences:view',

  EMAIL_NOTIFICATION_SETTINGS_VIEW = 'tab:setting:general:notification:view',
  EMAIL_NOTIFICATION_SETTINGS_EDIT = 'tab:setting:general:notification:edit',

  TPV_SWAPPER_VIEW = 'tpv:swapper:view',

  USER_PREFERENCE_VIEW = 'userPreference:view',
  USER_APPT_PREFERENCE_EDIT = 'userApptPreference:edit',
  USER_RISK_PREFERENCE_EDIT = 'userRiskPreference:edit',

  REJECT_BUTTON_VIEW = 'button:rejectTPV:view',

  AFTER_SALES_APPOINTMENT_VIEW = 'aftersales:appointment:view',
  PRE_SALES_APPOINTMENT_VIEW = 'presales:appointment:view',
  GIVE_INSTRUCTIONS_VIEW = 'tab:giveinstructions:view',
  CREATE_ZENCHAT = 'treatmentplanversion:create:ticket',

  SEND_CONSENT_EMAIL = 'treatment:consent:send-mail',
  DOCTOR_WORKING_HOUR_EDIT = 'doctor:workinghours:edit',
  STAFFS_TABLE_VIEW = 'stafftable:view',

  UPDATE_COUNCIL = 'treatment:staff:update',
  CASEOPS_ISSUE = 'treatmentplan:edit',

  RESUBMISSION_FILES_BUTTON_VIEW = 'button:reuploadfiles:view',

  STREAM_TRANSLATION = 'translation:stream',

  RETAINER_PRESCRIPTION_ENABLED = 'retainer:prescription:enabled',
  FINANCE_TAB_VIEW = 'tab:finance:view',
  RESCHEDULE_ACCESS_DENY = 'reschedule:access:deny'
}
